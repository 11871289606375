import { Modal, Button, message } from "antd";
// import { QRCodeSVG } from "qrcode.react";
import React from "react";
import UpiQR from "../../../common/assets/images/upi-id.png";
// import { Cache } from "../../../../infrastructure/common";
// import { paymentLinks } from "../../constants";
// import { useBaseStore } from "../../stores/Base";
import "./index.css";
import PaymentProof from "../PaymentProof";

interface UpiModalProps {
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  totalAmount: number;
}

const UpiModal = ({ setShowModal, showModal, totalAmount }: UpiModalProps) => {
  const staticUpiId = "sarovar70235@sbi";
  const handleCopyUpiId = () => {
    navigator.clipboard.writeText(staticUpiId).then(
      () => {
        message.success("UPI ID copied to clipboard!");
      },
      () => {
        message.error("Failed to copy UPI ID. Please try again.");
      }
    );
  };

  return (
    <Modal
      centered
      className="!max-w-[85%] !w-[480px]"
      visible={showModal}
      footer={false}
      onCancel={() => setShowModal(false)}
      closable
    >
      <div className="w-full flex items-center justify-center flex-col">
        <img
          src={UpiQR}
          alt="Static QR Code"
          className="max-w-[300px] max-h-[300px] w-full h-auto mb-5"
        />
        <div className="text-themeBlue text-[14px]">UPI ID: {staticUpiId}</div>
        <Button type="primary" onClick={handleCopyUpiId} className="mt-2">
          Copy UPI ID
        </Button>
        <div className="mt-4">Amount to Pay: ₹{totalAmount}</div>
      </div>
      {totalAmount > 0 && <PaymentProof />}
    </Modal>
  );
};

export default UpiModal;
