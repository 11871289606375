import React, { useState, useEffect } from "react";
import { Alert, Button, Upload, UploadProps, Image } from "antd";
import {
  UploadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import { getDatabase, ref as dbRef, set, get } from "firebase/database";
import { useBaseStore } from "../../stores/Base";
import { Cache } from "../../../../infrastructure/common";
import moment from "moment";

const PaymentProof: React.FC = () => {
  const [{ user }] = useBaseStore();
  const [loading, setLoading] = useState(false);
  const [fileList, setFileList] = useState<any[]>([]);
  const [uploadable, setUploadable] = useState<boolean | null>(null);
  const [existingProofUrl, setExistingProofUrl] = useState<string | null>(null);
  const [isVerified, setIsVerified] = useState(false);
  const hostel = Cache.getItem("hostel");
  const previousMonth = moment().subtract(1, "months");
  const month: string = previousMonth.format("MM");
  const year: string = previousMonth.format("YY");
  const messNumber = user?.messno?.replace("mn", "");
  useEffect(() => {
    fetchExistingProof();
  }, []);

  const fetchExistingProof = async () => {
    if (!user) return;

    // const date: Date = new Date();
    // const month: string = moment(date).format("MM");
    // const year: string = moment(date).format("YY");
    // const messNumber = user.messno?.replace("mn", "");

    const db = getDatabase();
    const proofRef = dbRef(
      db,
      `${hostel}/paymentproofs/y${year}/m${month}/mn${messNumber}`
    );

    try {
      const snapshot = await get(proofRef);
      if (snapshot.exists()) {
        const data = snapshot.val();
        setExistingProofUrl(data.downloadURL);
        setIsVerified(data.verified);
      }
    } catch (error) {
      console.error("Error fetching existing proof:", error);
    }
  };

  const handleBeforeUpload = (file: File) => {
    const isJpgOrPng =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "application/pdf";
    const isLt2M = file.size / 1024 / 1024 < 2;

    if (!isJpgOrPng) {
      alert("You can only upload JPG/PNG/PDF file!");
    }

    if (!isLt2M) {
      alert("File must be smaller than 2MB!");
    }

    setUploadable(isJpgOrPng && isLt2M);
    return false;
  };

  const handleChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const uploadProof = async () => {
    if (!fileList[0] || !user) return;
    

    setLoading(true);
    const storage = getStorage();
    const fileName = `payment_proof_${Date.now()}${fileList[0].name.substr(
      fileList[0].name.lastIndexOf(".")
    )}`;
    const fileRef = storageRef(
      storage,
      `${hostel}/payment-proof/y${year}/m${month}/mn${messNumber}/${fileName}`
    );

    try {
      // Upload file to Firebase Storage
      const snapshot = await uploadBytes(fileRef, fileList[0].originFileObj);
      const downloadURL = await getDownloadURL(snapshot.ref);

      // Add payment proof to Realtime Database
      const db = getDatabase();
      const paymentProofRef = dbRef(
        db,
        `${hostel}/paymentproofs/y${year}/m${month}/mn${messNumber}`
      );
      await set(paymentProofRef, {
        downloadURL: downloadURL,
        verified: false,
      });

      setExistingProofUrl(downloadURL);
      setIsVerified(false);
      alert("Payment proof uploaded and recorded successfully!");
      setFileList([]);
    } catch (error) {
      console.error("Error uploading payment proof:", error);
      alert("Failed to upload payment proof. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const uploadProps: UploadProps = {
    beforeUpload: handleBeforeUpload,
    onChange: handleChange,
    maxCount: 1,
    fileList,
  };

  return (
    <div className="w-full p-6">
      <Alert
        message="Upload Payment Proof"
        description="Please upload an image or PDF of your payment receipt. The file should be less than 2MB."
        type="info"
        showIcon
        className="mb-4"
      />
      {existingProofUrl && (
        <div className="mb-4">
          <h3 className="flex items-center">
            Existing Payment Proof:
            {isVerified ? (
              <div className="flex items-center">
                <CheckCircleOutlined className="ml-2 text-green-500" />
                <span className="ml-1 text-green-500">Verified</span>
              </div>
            ) : (
              <div className="flex items-center">
                <CloseCircleOutlined className="ml-2 text-red-500" />
                <span className="ml-1 text-red-500">Not Verified</span>
              </div>
            )}
          </h3>
          <Image
            src={existingProofUrl}
            alt="Existing Payment Proof"
            style={{ maxWidth: "100%", maxHeight: "300px" }}
          />
        </div>
      )}
      {!isVerified && (
        <>
          <Upload {...uploadProps} listType="picture">
            <Button icon={<UploadOutlined />}>Select File</Button>
          </Upload>
          <Button
            size="large"
            className="w-full mt-4 bg-[#00796B] text-white border-transparent hover:border-[#00ad99ab]"
            loading={loading}
            onClick={uploadProof}
            disabled={!uploadable || fileList.length === 0}
          >
            Upload Payment Proof
          </Button>
        </>
      )}
    </div>
  );
};

export default PaymentProof;
